import React from "react";
import {Container, Grid, Link, Typography} from "@material-ui/core";

export default ({classes}) =>
    <div className={classes.footer}>
        <Container maxWidth="lg">
            <Grid container>
                <Grid item xs={6}>
                    <Typography>© {new Date().getFullYear()} CANACO</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography align="right">
                        <Link href={"mailto:admin@canaco.org"}>admin@canaco.org</Link>
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    </div>;