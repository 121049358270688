import React, {useEffect} from 'react';

export default function YandexAd({yandexCode}) {
    useEffect(() => {
        const adBlock = {
            blockId: yandexCode,
            renderTo: `yandex_rtb_${yandexCode}`,
            async: true
        };
        try {
            if (window.yandexContextAsyncCallbacks === undefined) {
                window.yandexContextAsyncCallbacks = [];
            }
            window.yandexContextAsyncCallbacks.push(() => {
                window.Ya.Context.AdvManager.render(adBlock);
            });
            let firstScript = window.document.getElementsByTagName("script")[0];
            let script = window.document.createElement("script");
            script.type = "text/javascript";
            script.src = "//an.yandex.ru/system/context.js";
            script.async = true;
            firstScript.parentNode.insertBefore(script, firstScript);
        } catch (e) {
            console.log(e);
        }
    });

    return <div id={`yandex_rtb_${yandexCode}`}/>;
}
