import React from "react";
import {Container, Typography} from "@material-ui/core";
import logoCn from "./img/cn_logo.svg";
import DownloadFiles from "./components/DownloadFilesContainer";
import {Helmet} from "react-helmet/es/Helmet";

export default ({t, classes, ad}) => (
    <React.Fragment>
        <Helmet>
            <title>{t("downloads")} — {t("caps_navigator_community")}</title>
        </Helmet>
        <Container maxWidth="lg">
            <img src={logoCn} alt="Caps Navigator" className={classes.logoCn}/>
            <Typography variant={"h3"} align={"center"}>
                {t("installation_instructions")}
            </Typography>
            <Typography paragraph>
                {t("installation_instructions_text")}
            </Typography>
        </Container>
        <Container maxWidth="md" className={classes.adWrapper}>
            {ad}
        </Container>
        <Container maxWidth="md">
            <Typography variant={"h3"} align={"center"}>
                {t("files_for_download")}
            </Typography>
        </Container>
        <Container maxWidth="md">
            <DownloadFiles/>
        </Container>
    </React.Fragment>
);