import React from "react";
import {
    FormControl,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import {Helmet} from "react-helmet/es/Helmet";
import mapData from "./CollectionsDataMapper";

const StubRow = ({lineNumber, classes}) => {
    const stubWidths = ["30%", "45%", "40%", "52%", "33%"];

    return (
        <TableRow>
            <TableCell scope="row" className={classes.iconCell}/>
            <TableCell className={classes.nameCell}>
                <Skeleton variant="text" width={stubWidths[(lineNumber + 2) % 5]}/>
            </TableCell>
            <TableCell>
                <Skeleton variant="text" width={stubWidths[(lineNumber + 1) % 5]}/>
            </TableCell>
            <TableCell>
                <Skeleton variant="text" width={stubWidths[(lineNumber + 3) % 5]}/>
            </TableCell>
            <TableCell className={classes.totalItemsCell}>
                <Skeleton variant="text" width={stubWidths[(lineNumber + 4) % 5]}/>
            </TableCell>
        </TableRow>
    )
}

export default ({t, i18n, classes, match: {params}, data: {collections, loading, error}, history, ad}) => {

    const changePageHandler = (event, newPage) => {
        let url = getCanonicalUrl();
        if (newPage + 1 > 1) {
            url += `${newPage + 1}/`;
        }
        history.push(url);
    };

    const language = i18n.language.substr(0, 2);

    const changeCollectionTypeHandler = event => {
        let url = `/${language}/collections/`;
        if (event.target.value) {
            url += `${event.target.value}/`;
        }
        history.push(url);
    };

    const getCanonicalUrl = () => {
        let url = `/${language}/collections/`;
        if (params.collectionType) {
            url += `${params.collectionType}/`;
        }
        return url;
    }

    return (
        <Paper>
            {params.page > 1 && (
                <Helmet>
                    <link rel="canonical" href={getCanonicalUrl()}/>
                </Helmet>
            )}
            <TableContainer>
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.iconHeader}/>
                            <TableCell className={classes.nameHeader}>{t("site_name")}</TableCell>
                            <TableCell>{t("owner")}</TableCell>
                            <TableCell className={classes.collectionTypeHeader}>
                                <FormControl>
                                    <Select
                                        value={params.collectionType || ""}
                                        onChange={changeCollectionTypeHandler}
                                        displayEmpty
                                        className={classes.collectionTypeSelect}
                                    >
                                        <MenuItem value="">{t("collection_type")}</MenuItem>
                                        <MenuItem value="crown_caps">{t("crown_caps")}</MenuItem>
                                        <MenuItem value="pet_caps">{t("pet_caps")}</MenuItem>
                                        <MenuItem value="screw_caps">{t("screw_caps")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell align="right">{t("caps_amount")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(loading && (
                            [...Array(20)].map((_, index) =>
                                <StubRow lineNumber={index} classes={classes} key={`stub_line_${index}`}/>
                            )
                        ))
                        || (error && (
                            <TableRow>
                                <TableCell scope="row" colSpan={5} align="center">
                                    {t("loading_error")}
                                </TableCell>
                            </TableRow>
                        ))
                        || (mapData(collections, language, t).map(
                            (collection, index) => (
                                <React.Fragment key={collection.hostName}>
                                    {index === Math.floor(collections.list.length / 2) && (
                                        <TableRow>
                                            <TableCell scope="row" colSpan={5} className={classes.adWrapper}>
                                                {ad}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow>
                                        <TableCell scope="row" className={classes.iconCell}>
                                            {collection.iconUrl && (
                                                <img src={collection.iconUrl} alt=""/>
                                            )}
                                        </TableCell>
                                        <TableCell className={classes.nameCell}>
                                            <a href={collection.url}
                                               className={classes.tableLink}>{collection.name}</a>
                                        </TableCell>
                                        <TableCell>
                                            {collection.owner}
                                        </TableCell>
                                        <TableCell>
                                            {collection.collectionType}
                                        </TableCell>
                                        <TableCell className={classes.totalItemsCell}>
                                            {collection.totalItems}
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {!loading && !error && collections.amount > 0 &&
            <TablePagination
                rowsPerPageOptions={[20]}
                component="div"
                count={collections.amount}
                rowsPerPage={20}
                page={(params.page || 1) - 1}
                onChangePage={changePageHandler}
                backIconButtonText={t("previous_page")}
                nextIconButtonText={t("next_page")}
                labelDisplayedRows={({from, to, count}) => `${from}-${to === -1 ? count : to} / ${count}`}
            />
            }
        </Paper>
    );
}