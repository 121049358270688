import React, {useEffect} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import MainPage from "./MainPageContainer";
import DownloadPage from "./DownloadPageContainer";
import CollectionsPage from "./CollectionsPageContainer";
import background from "./img/background.svg";
import {Helmet} from "react-helmet/es/Helmet";
import NotFoundPage from "./NotFoundPageContainer";
import UnderConstructionPage from "./UnderConstructionPageContainer";
import {getAvailableLanguageRegexp} from "./lib/languageHelper";
import {COLLECTION_TYPE_RE, PAGE_EXCEPT_FIRST_RE} from "./lib/urlHelper";
import Ad from "./components/Ad";
import gtag from "./lib/gtag";

export default ({i18n, classes}) => {
    const langRegexp = getAvailableLanguageRegexp();
    const ad = <Ad/>;
    const location = useLocation();

    useEffect(
        () => {
            gtag('event', 'page_view', {
                page_location: location.href,
                page_path: location.pathname
            });
        },
        [location]
    );

    useEffect(() => {
        gtag("js", new Date());
        gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }, []);

    return (
        <main className={classes.content}>
            <Helmet>
                <html lang={i18n.language.substr(0, 2)}/>
            </Helmet>
            <div className={classes.overlay}>
                <img src={background} alt=""/>
                <img src={background} alt=""/>
            </div>
            <Switch>
                <Route exact
                       path={`/${langRegexp}/collections/:collectionType${COLLECTION_TYPE_RE}?/:page${PAGE_EXCEPT_FIRST_RE}?/`}>
                    <CollectionsPage ad={ad}/>
                </Route>
                <Route exact path={`/${langRegexp}/download/`}>
                    <DownloadPage ad={ad}/>
                </Route>
                <Route exact path={`/${langRegexp}/personal/`}>
                    <UnderConstructionPage/>
                </Route>
                <Route exact path={`/${langRegexp}/`}>
                    <MainPage ad={ad}/>
                </Route>
                {i18n.isInitialized && (
                    <Route exact path="/">
                        <Redirect to={`/${i18n.language.substr(0, 2)}/`}/>
                    </Route>
                )}
                {i18n.isInitialized && (
                    <Route path="*" component={NotFoundPage} status={404}/>
                )}
            </Switch>
        </main>
    );
};