import React from "react";
import {useHistory} from "react-router-dom";
import {
    Toolbar,
    AppBar,
    IconButton,
    Typography,
    FormControl,
    Select,
    Grid,
    Container
} from "@material-ui/core";
import {Menu as MenuIcon} from "@material-ui/icons";
import {availableLanguages} from "../lib/languageHelper";
import MobileMenu from "./MobileMenuContainer";

export default ({t, i18n, classes, toggleMobileMenu}) => {
    const changeLanguage = event => {
        let oldPath = window.location.pathname;
        let newPath = oldPath.replace(/\/[a-z]{2}\/([a-z/]*)/g, `/${event.target.value}/$1`);
        i18n.changeLanguage(event.target.value);
        history.push(newPath + window.location.search);
    };

    const history = useHistory();

    return (
        <AppBar position="static">
            <Toolbar disableGutters>
                <Container maxWidth="lg">
                    <Grid container>
                        <Grid item xs={8} className={classes.toolbarCell}>
                            <IconButton edge="start" color="inherit" onClick={() => toggleMobileMenu()}>
                                <MenuIcon/>
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {t("caps_navigator_community")}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} className={classes.toolbarCell}>
                            <FormControl variant="outlined" size="small">
                                <Select
                                    className={classes.select}
                                    defaultValue={i18n.language.substr(0, 2)}
                                    onChange={changeLanguage}
                                    inputProps={{
                                        name: "language",
                                        id: "language",
                                    }}
                                >
                                    {availableLanguages.map(
                                        language => (
                                            <option value={language.code} key={language.code}>{language.name}</option>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Container>
            </Toolbar>
            <MobileMenu/>
        </AppBar>
    );
};