import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {Helmet} from "react-helmet/es/Helmet";

export default ({t, classes, title, header, description}) => (
    <React.Fragment>
        <Helmet>
            <title>{title} — {t("caps_navigator_community")}</title>
        </Helmet>
        <Container maxWidth="md">
            <Typography variant={"h3"} align={"center"} className={classes.header404}>{header}</Typography>
            <Typography variant={"h5"} align={"center"}>{description}</Typography>
        </Container>
    </React.Fragment>
);