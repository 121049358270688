export default () => ({
    content: {
        paddingTop: 30,
    },
    overlay: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        opacity: 0.04,
        zIndex: -1,
        minHeight: 1300,
        "& img": {
            position: "absolute",
            width: "76vw",
            "&:first-child": {
                left: 0,
                top: 40,
            },
            "&:last-child": {
                right: 0,
                bottom: 40,
                transform: "rotate(180deg)",
            }
        }
    }
});