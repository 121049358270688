import {getClosestLanguage} from "../lib/languageHelper";

const getCollectionName = (translations, hostName, language) => {
    const availableLanguages = translations
        .filter(translation => translation.siteName)
        .map(translation => translation.language);
    const translateTo = getClosestLanguage(language, availableLanguages);
    if (translateTo === undefined) {
        return hostName;
    }
    for (let key in translations) {
        if (!translations.hasOwnProperty(key)) {
            continue;
        }
        if (translations[key].language === translateTo) {
            return translations[key].siteName;
        }
    }
};

export default (collections, language, t) => {
    if (!collections) {
        return [];
    }

    return collections.list.map(collection => ({
        hostName: collection.host.hostName,
        iconUrl: collection.host.iconUrl,
        url: collection.host.url,
        name: getCollectionName(collection.host.translations, collection.host.hostName, language),
        owner: `${collection.host.user.firstName} ${collection.host.user.lastName}`,
        collectionType: t(collection.collectionType),
        totalItems: collection.totalItems,
    }));
};