import React from "react";
import {Container, Typography} from "@material-ui/core";
import Collections from "./components/CollectionsContainer";
import {Helmet} from "react-helmet/es/Helmet";

export default ({t, ad}) => (
    <React.Fragment>
        <Helmet>
            <title>{t("collections")} — {t("caps_navigator_community")}</title>
        </Helmet>
        <Container maxWidth="md">
            <Typography variant={"h3"} align={"center"}>
                {t("collections")}
            </Typography>
        </Container>
        <Container maxWidth="md">
            <Collections ad={ad}/>
        </Container>
    </React.Fragment>
);