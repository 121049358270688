import React from "react";
import {Container, Typography, Box} from "@material-ui/core";
import logo from "./img/logo.svg";
import logoCn from "./img/cn_logo.svg";
import collectionViewCn from "./img/caps_navigator_collection_view.png";
import collectionViewCanaco from "./img/canaco_collection_view.png";
import syncArrow from "./img/sync_arrow.svg";
import {Helmet} from "react-helmet/es/Helmet";

export default ({t, classes, ad}) => (
    <React.Fragment>
        <Helmet>
            <title>{t("caps_navigator_community")}</title>
        </Helmet>
        <Container maxWidth="md" className={classes.logoContainer}>
            <img src={logo} alt={t("logo")} className={classes.logo}/>
            <Box className={classes.logoTitles}>
                <Typography variant={"h3"}>
                    {t("caps_navigator_community")}
                </Typography>
                <Typography variant={"h5"}>
                    {t("site_description")}
                </Typography>
            </Box>
        </Container>
        <Container maxWidth="lg">
            <Typography variant={"h3"} align={"center"}>
                {t("what_is_canaco")}
            </Typography>
            <Typography paragraph>
                {t("what_is_canaco_text")}
            </Typography>
        </Container>
        <Container maxWidth="md" className={classes.syncContainer}>
            <img src={collectionViewCn} alt="Caps Navigator" className={classes.collectionViewCn}/>
            <img src={syncArrow} alt="" className={classes.syncArrow}/>
            <img src={collectionViewCanaco} alt="CANACO" className={classes.collectionViewCanaco}/>
        </Container>
        <Container maxWidth="md" className={classes.adWrapper}>
            {ad}
        </Container>
        <Container maxWidth="lg">
            <Typography variant={"h3"} align={"center"}>
                {t("a_little_bit_about_the_caps_navigator")}
            </Typography>
            <Typography paragraph>
                <img src={logoCn} alt="Caps Navigator" className={classes.logoCn}/>
                {t("a_little_bit_about_the_caps_navigator_text")}
            </Typography>
        </Container>
    </React.Fragment>
);