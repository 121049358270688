import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {availableLanguages} from "./lib/languageHelper";

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
        fallbackLng: "en",
        whitelist: availableLanguages.map(lang => lang.code),
        debug: false,
        load: "languageOnly",
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ["path", "cookie", "localStorage", "navigator", "htmlTag"],
            lookupCookie: "i18next",
            lookupLocalStorage: "i18nextLng",
            lookupFromPathIndex: 0,
            caches: ["localStorage", "cookie"],
            excludeCacheFor: ["cimode"],
            cookieMinutes: 10,
            cookieDomain: "canaco.org",
            checkWhitelist: true
        }
    }, null).then(null);

export default i18n;