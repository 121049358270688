import React from "react";
import {List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer} from "@material-ui/core";
import {
    Collections as CollectionsIcon,
    GetApp as GetAppIcon,
    Home as HomeIcon,
    Person as PersonIcon
} from "@material-ui/icons";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

function ListItemLink(props) {
    const {icon, primary, to} = props;

    const renderLink = React.useMemo(
        () => React.forwardRef(
            (itemProps, ref) =>
                <Link to={to} ref={ref} {...itemProps} />),
        [to],
    );

    return (
        <ListItem button component={renderLink}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText primary={primary}/>
        </ListItem>
    );
}

ListItemLink.propTypes = {
    icon: PropTypes.element,
    primary: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};

export default ({t, i18n, classes, mobileMenuOpen, toggleMobileMenu}) => {
    const toggleMenu = () => event => {
        if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        toggleMobileMenu();
    };

    const menus = [
        {
            "url": "/",
            "icon": <HomeIcon/>,
            "title": t("main_page"),
        }, {
            "url": "/collections/",
            "icon": <CollectionsIcon/>,
            "title": t("collections"),
        }, {
            "url": "/download/",
            "icon": <GetAppIcon/>,
            "title": t("downloads"),
        }, {
            "url": "/personal/",
            "icon": <PersonIcon/>,
            "title": t("registration"),
        },
    ];

    return (
        <SwipeableDrawer
            open={mobileMenuOpen}
            onClose={toggleMenu(false)}
            onOpen={toggleMenu(true)}
        >
            <div
                className={classes.list}
                role="presentation"
                onClick={toggleMenu(false)}
                onKeyDown={toggleMenu(false)}
            >
                <List>
                    {menus.map((menuItem) => (
                        <ListItemLink to={"/" + i18n.language.substr(0, 2) + menuItem.url}
                                      primary={menuItem.title}
                                      icon={menuItem.icon}
                                      key={menuItem.url}/>
                    ))}
                </List>
            </div>
        </SwipeableDrawer>
    );
};
