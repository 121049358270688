import {withStyles} from "@material-ui/core/styles";
import MobileMenu from "./MobileMenu";
import styles from "./MobileMenuStyles";
import compose from "recompose/compose";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {toggleMobileMenu} from "../redux/actions";

export default compose(
    withTranslation(),
    withStyles(styles),
    connect(
        state => ({
            mobileMenuOpen: state.common.mobileMenuOpen,
        }), {
            toggleMobileMenu,
        }
    ),
)(MobileMenu);