import React from "react";
import {ThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {BrowserRouter as Router} from "react-router-dom";
import {theme} from "./Theme";
import {ApolloProvider} from "react-apollo";
import {apolloClient} from "./apolloClient";
import Header from "./components/HeaderContainer";
import Footer from "./components/FooterContainer";
import MainWrapper from "./MainWrapperContainer";
import Spinner from "./components/SpinnerContainer";
import {I18nextProvider} from "react-i18next";
import {Provider as ReduxProvider} from "react-redux";
import i18n from "./i18n";
import store from "./redux/store";

export default () =>
    <Router>
        <ThemeProvider theme={theme}>
            <React.Suspense fallback={<Spinner/>}>
                <I18nextProvider i18n={i18n}>
                    <ReduxProvider store={store}>
                        <ApolloProvider client={apolloClient} store={store}>
                            <CssBaseline/>
                            <Header/>
                            <MainWrapper/>
                            <Footer/>
                        </ApolloProvider>
                    </ReduxProvider>
                </I18nextProvider>
            </React.Suspense>
        </ThemeProvider>
    </Router>