import React from "react";
import {PulseLoader} from "react-spinners";

export default ({classes}) =>
    <div className={classes.root}>
        <PulseLoader
            size={10}
            color={"#999"}
        />
    </div>
