import {
    TOGGLE_MOBILE_MENU
} from "../actionTypes";

const initialState = {
    mobileMenuOpen: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_MOBILE_MENU: {
            return {
                ...state,
                mobileMenuOpen: !state.mobileMenuOpen,
            };
        }
        default:
            return state;
    }
}
