import {createMuiTheme} from "@material-ui/core/styles";

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#078a2a',
        },
        secondary: {
            main: '#8a070b',
        },
        background: {
            default: '#eee',
            paper: '#fff',
        },
        text: {
            primary: '#333',
            secondary: '#333',
        }
    },
    typography: {
        h1: {
            fontWeight: 300,
        },
        h2: {
            fontWeight: 300,
        },
        h3: {
            fontWeight: 300,
            paddingTop: 30,
            paddingBottom: 16,
        },
        h4: {
            fontWeight: 400,
        },
        h5: {
            fontWeight: 400,
        },
    },
});