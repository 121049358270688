import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import Collections from "./Collections";
import {graphql} from "react-apollo";
import QUERY_COLLECTIONS from "./CollectionsGraphql";
import {withStyles} from "@material-ui/core/styles";
import styles from "./CollectionsStyles";
import {withRouter} from "react-router-dom";

export default compose(
    withRouter,
    withStyles(styles),
    withTranslation(),
    graphql(QUERY_COLLECTIONS, {
        options: ({match: {params}}) => ({
            variables: {
                collectionType: params.collectionType,
                page: params.page,
            }
        })
    }),
)(Collections);