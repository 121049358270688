const availableLanguages = [
    {code: "ca", name: "Català"},
    {code: "de", name: "Deutsch"},
    {code: "en", name: "English"},
    {code: "es", name: "Español"},
    {code: "fr", name: "Français"},
    {code: "it", name: "Italian"},
    {code: "nl", name: "Nederlandse"},
    {code: "pl", name: "Polski"},
    {code: "pt", name: "Português"},
    {code: "cs", name: "Český"},
    {code: "ru", name: "Русский"},
    {code: "uk", name: "Українська"},
];

const getAvailableLanguageRegexp = () => `(${availableLanguages.map(lang => lang.code).join("|")})`;

/**
 * Finds closest language for fromLanguage in availableLanguages array
 * @link https://en.wikipedia.org/wiki/Mutual_intelligibility
 * @link https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 */
const getClosestLanguage = (fromLanguage, availableLanguages) => {
    if (typeof availableLanguages !== "object") {
        return;
    }
    if (availableLanguages.length === 1) {
        return availableLanguages[0];
    }
    if (availableLanguages.includes(fromLanguage)) {
        return fromLanguage;
    }

    const mutuallyIntelligibleLanguages = {
        ru: ["be", "uk"],
        be: ["ru", "uk"],
        es: ["gl", "pt", "ca", "it"],
        ca: ["oc", "it", "es", "pt"],
        nl: ["af", "li", "fy"],
        en: ["gd"],
        fr: ["ca", "ro", "it", "es", "pt"],
        gl: ["pt", "es", "ca"],
        de: ["lb"],
        ga: ["gd"],
        it: ["co", "fr", "ca", "es", "sc", "pt"],
        lb: ["de"],
        li: ["nl", "af"],
        pl: ["sk", "cs"],
        pt: ["gl", "es", "ca", "it"],
        az: ["tr"],
        tr: ["az"],
        et: ["fi"],
        fi: ["et"],
        mt: ["sc"],
        af: ["nl"],
        bg: ["mk"],
        co: ["it", "sc"],
        hr: ["sr", "sl"],
        cs: ["sk", "pl"],
        da: ["no", "sv"],
        mk: ["bg"],
        no: ["da", "sv"],
        oc: ["ca"],
        ro: ["fr", "it"],
        sc: ["co", "it"],
        sr: ["hr", "sl", "mc"],
        sk: ["cs", "pl"],
        sl: ["hr", "sr"],
        sv: ["da", "no"],
        uk: ["be", "ru"],
        zu: ["xh"],
    };

    const closeLangs = mutuallyIntelligibleLanguages[fromLanguage];
    if (closeLangs !== undefined) {
        for (let key in closeLangs) {
            if (!closeLangs.hasOwnProperty(key)) {
                continue;
            }
            if (availableLanguages.includes(closeLangs[key])) {
                return closeLangs[key];
            }
        }
    }

    const languageFamilies = [
        [// Indo-European
            "af", "sq", "an", "hy", "as", "ae", "be", "bn", "bh", "bs", "br", "bg", "ca", "kw", "co", "hr", "cs", "da",
            "dv", "nl", "en", "fo", "fr", "gl", "de", "el", "gu", "hi", "ga", "is", "it", "ks", "ku", "la", "lb", "li",
            "lt", "lv", "gv", "mk", "mr", "ne", "nb", "nn", "no", "oc", "cu", "or", "os", "pa", "pi", "fa", "pl", "ps",
            "pt", "rm", "ro", "ru", "sa", "sc", "sd", "sr", "gd", "si", "sk", "sl", "es", "sv", "tg", "uk", "ur", "wa",
            "cy", "fy", "yi"
        ],
        [// Uralic
            "et", "fi", "hu", "kv", "se"
        ],
        [// Turkic
            "az", "ba", "cv", "kk", "ky", "tk", "tr", "tt", "ug", "uz"
        ],
        [// Afro-Asiatic
            "aa", "am", "ar", "ha", "he", "mt", "om", "so", "ti"
        ],
        [// Algonquian
            "cr", "oj"
        ],
        [// Austroasiatic
            "km", "vi"
        ],
        [// Austronesian
            "ch", "fj", "ho", "id", "jv", "mg", "ms", "mi", "mh", "na", "sm", "su", "tl", "to", "ty"
        ],
        [// Creole
            "bi", "ht", "sg"
        ],
        [// Dravidian
            "kn", "ml", "ta", "te"
        ],
        [// Eskimo–Aleut
            "ik", "iu", "kl"
        ],
        [// Niger–Congo
            "ak", "bm", "ny", "ee", "ff", "hz", "ig", "ki", "rw", "kg", "kj", "lg", "ln", "lu", "nd", "ng", "nr", "rn",
            "sn", "st", "sw", "ss", "tn", "ts", "tw", "ve", "wo", "xh", "yo", "zu"
        ],
        [// Northeast Caucasian
            "av", "ce", "ab"
        ],
        [// Sino-Tibetan
            "my", "zh", "dz", "ii", "bo"
        ],
        [// Tai–Kadai
            "lo", "th", "za"
        ],
    ];
    for (let familyKey in languageFamilies) {
        if (!languageFamilies[familyKey].includes(fromLanguage)) {
            continue;
        }
        for (let key in availableLanguages) {
            if (!availableLanguages.hasOwnProperty(key)) {
                continue;
            }
            if (languageFamilies[familyKey].includes(availableLanguages[key])) {
                return availableLanguages[key];
            }
        }
    }

    const internationalLanguages = ["en", "es", "fr", "ru", "pt", "de", "ar", "zh"];
    for (let key in availableLanguages) {
        if (!availableLanguages.hasOwnProperty(key)) {
            continue;
        }
        if (internationalLanguages.includes(availableLanguages[key])) {
            return availableLanguages[key];
        }
    }

    return availableLanguages[0];
};

export {availableLanguages, getAvailableLanguageRegexp, getClosestLanguage}