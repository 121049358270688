export default theme => ({
    iconHeader: {
        maxWidth: 20,
        paddingRight: 0,
    },
    nameHeader: {
        minWidth: 250,
        paddingLeft: 8,
    },
    collectionTypeHeader: {
        minWidth: 150,
    },
    collectionTypeSelect: {
        fontSize: "0.875rem",
    },
    iconCell: {
        paddingRight: 0,
        "& > img": {
            width: 16,
            height: 16,
        }
    },
    totalItemsHeader: {
        minWidth: 120,
    },
    nameCell: {
        paddingLeft: 8,
    },
    totalItemsCell: {
        textAlign: "right",
    },
    tableLink: {
        color: theme.palette.text.primary,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline"
        }
    },
    adWrapper: {
        padding: "0 !important",
    }
});