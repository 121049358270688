import gql from "graphql-tag";

export default gql`
  query ($collectionType: String, $page: Int){
    collections(collectionType: $collectionType, page: $page) {
      list {
        totalItems
        collectionType
        host {
          url
          iconUrl
          hostName
          user {
            firstName
            lastName
          }
          translations {
            language
            siteName
          }
        }
      }
      page
      numPages
      amount      
    }
  }
`;