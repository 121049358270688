import {withStyles} from "@material-ui/core/styles";
import Header from "./Header";
import styles from "./HeaderStyles";
import compose from "recompose/compose";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {toggleMobileMenu} from "../redux/actions";

export default compose(
    withTranslation(),
    withStyles(styles),
    connect(
        null,
        {
            toggleMobileMenu,
        }
    ),
)(Header);