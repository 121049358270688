export default theme => ({
    mainGrid: {
        marginTop: theme.spacing(3),
    },
    logoContainer: {
        paddingTop: 50,
        paddingBottom: 50,
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        [theme.breakpoints.up("md")]: {
            paddingTop: 100,
            paddingBottom: 100,
            flexDirection: "row",
            textAlign: "left",
        },
    },
    logo: {
        minWidth: 195,
        height: 175,
        margin: "0 auto",
        [theme.breakpoints.up("md")]: {
            marginRight: 64,
        },
    },
    logoTitles: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    syncContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
            justifyContent: "space-between",
            flexDirection: "row",
        },
    },
    collectionViewCn: {
        width: 292,
        height: 196,

    },
    collectionViewCanaco: {
        width: 280,
        height: 196,
    },
    syncArrow: {
        [theme.breakpoints.up("md")]: {
            transform: "rotate(90deg)",
        },
    },
    logoCn: {
        float: "left",
        padding: "0 20px 5px 0",
    },
    adWrapper: {
        paddingTop: 20,
        paddingBottom: 20,
    }
});