const formatDateTime = (dateTimeString, language) => {
    let dateTime = new Date(dateTimeString);
    return new Intl.DateTimeFormat(language.substr(0, 2)).format(dateTime)
};

const formatFileSize = (bytes, decimals = 1) => {
    if (bytes === 0) {
        return "0 Bytes";
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export default (allDownloadFiles, language) => {
    if (!allDownloadFiles) {
        return [];
    }

    return allDownloadFiles.map(file => ({
        name: file.name,
        url: `/backend_static/downloads/${file.name}`,
        modified: formatDateTime(file.modified, language),
        size: formatFileSize(file.size),
    }));
};