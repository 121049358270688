import React from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from "@material-ui/core";
import mapData from "./DownloadFilesDataMapper";

export default ({t, i18n, data: {allDownloadFiles, loading, error}, classes}) => {

    if (loading) {
        return <div align={"center"}>{t("loading")}</div>
    }
    if (error) {
        return <div align={"center"}>{t("loading_error")}</div>
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("file_name")}</TableCell>
                        <TableCell align="center">{t("modified")}</TableCell>
                        <TableCell align="right">{t("size")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mapData(allDownloadFiles, i18n.language).map(file => (
                        <TableRow key={file.name}>
                            <TableCell component="th" scope="row">
                                <a href={file.url} className={classes.tableLink}>{file.name}</a>
                            </TableCell>
                            <TableCell align="center">{file.modified}</TableCell>
                            <TableCell align="right">{file.size}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};