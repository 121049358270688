export default theme => ({
    footer: {
        background: theme.palette.secondary.light,
        color: "#fff",
        position: "absolute",
        bottom: 0,
        padding: "15px 0",
        width: "100%",
        "& a": {
            color: "#fff",
        }
    }
});