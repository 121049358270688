export default theme => ({
    title: {
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    mainGrid: {
        marginTop: theme.spacing(3),
    },
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
    select: {
        color: "#fff",
        borderColor: "#fff",
        "& fieldset": {
            borderColor: "inherit",
        },
        "&:hover fieldset": {
            borderColor: "inherit !important",
        },
        "&.Mui-focused fieldset": {
            borderColor: "inherit !important",
            borderWidth: "1px !important",
        },
        "& .MuiSelect-icon": {
            color: "inherit",
        }
    },
    toolbarCell: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        whiteSpace: "nowrap",
        "&:last-child": {
            justifyContent: "flex-end",
        }
    },
});