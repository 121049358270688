export default () => ({
    logoCn: {
        width: 220,
        margin: "30px auto",
        display: "block",
    },
    adWrapper: {
        paddingTop: 20,
        paddingBottom: 20,
    }
});